<template>
	<div class=''>
		<mzSwiper :image='bannerImg' />
		<!-- 导航栏 -->
		<div class="flex flex-wrap row-between ">
			<div :class="['nav_item mb5',item.url==navUrl?'nav_item_avtive':'',i==4?'w-max':'']" v-for="(item,i) in navList"
				:key="i" @click="jump(item.url,i)">{{item.title}}
			</div>
		</div>
		<!-- //导航栏 -->
		<router-view></router-view>
	</div>
</template>

<script>
	//引入顶部banner的minix
	import bannerImgJs from '@/mixin/bannerImg.js'
	import {mapState} from 'vuex'
	export default {
		name:'aboutUs',
		created() {
			// console.log(111,this.$route);
			this.$store.commit('editNavUrl',this.$route.path)
		},
		computed:{
			...mapState(['navUrl'])
		},
		data() {
			return {
				navList: [{
						title: "关于铭智生科",
						url: '/aboutUs'
					}, {
						title: "团队介绍",
						url: '/teamInfo'
					},
					// {
					// 	title: "专家顾问",
					// 	url: '/expertConsultant'
					// },
					{
						title: "合作案例",
						url: '/cooperationCase'
					}, {
						title: "联系我们",
						url: '/contactUs'
					}
				],
				// active: sessionStorage.getItem('mz-m-about-id')||0,
				bannerType: 6
			};
		},
		mixins: [bannerImgJs],
		components: {},
		mounted() {},
		methods: {
			jump(url, i) {
				// sessionStorage.setItem('mz-m-about-id',i)
				this.active = i
				this.$router.push(url)
			}
		}
	}
</script>
<style lang='scss' scoped>
	.nav_item,
	.nav_item_avtive {
		width: 49.3%;
		height: 44px;
		background: #F2F2F2;
		font-size: 12px;
		font-weight: 500;
		color: #787878;
		line-height: 44px;
		text-align: center;
		font-weight: 500;
	}

	.nav_item_avtive {
		color: #000000 !important;
		background: #F7B500 !important;
	}
</style>
